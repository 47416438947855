<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="space-around">
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col align="center">
            <h2>Caja de Acción Social - Descuentos</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.cas" placeholder="Archivo CAS" label="Archivo enviado por CAS">
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.prestamo" placeholder="Archivo Municipalidad"
              label="Archivo Prestamo Descontado"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn elevation="2" color="primary" :disabled="loading" :loading="loading" @click="upload">Aceptar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          cas: undefined,
          prestamo: undefined
        } 
      }
    },

    methods: {
      async upload() {
        if (!this.form.cas) return this.$toast.push("info", "Por favor seleccione un archivo de la Caja de Acción Social");
        if (!this.form.prestamo) return this.$toast.push("info", "Por favor seleccione un archivo de préstamos descontados");
        this.loading = true;
        await this.$api.sendForm("cas2", this.form);
        this.loading = false;
      }
    }
  }
</script>